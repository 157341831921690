/* eslint-disable no-unused-vars */
import React, {useState, useCallback} from 'react'
import { useDispatch, useSelector } from "react-redux";
// import { useForm, Controller } from 'react-hook-form'
// import toast from 'react-hot-toast'
// import EmailModal from '../../Modal/EmailModal';
// import ReactQuill from 'react-quill';
import { updateBugStatus, updateBugSection, assignBugToUser, deleteBug, sendEmailToUser } from '../../../services/operations/bugs'
// import BugDetailsModal from '../../Modal/BugDetailsModal'
// import { setEmailContent } from '../../../slices/bugSlice';
import DeleteModal from '../../Modal/DeleteModal';
import { AlertTriangle } from 'lucide-react';

const BugsTableItem = (props) => {
    const {id,  status, title, num, category, time, email, assignee, openModal } = props
    const [bugStatus, setBugStatus] = useState(status || '');
    const [bugCategory, setBugCategory] = useState(category || '');

    // const [emailModalOpen, setEmailModalOpen] = useState(false);
    // const [isSending, setIsSending] = useState(false);
    const [assignedTo, setAssignedTo] = useState(assignee || '');
    const [deleteModal, setDeleteModal] = useState(false)

    const { teamMembers } = useSelector((state) => state.bugs);

    const dispatch = useDispatch()
    
    // const emailMessage = `
    // <p>Hi,</p><br/>
    // <p>Thank you for reaching out to us.</p>
    // <p>Our team has resolved the bug titled <strong>${title}</strong> successfully, and we hope it no longer affects the system's functionality.</p>
    // <p>If you face any problems, do not hesitate to reach out to us.</p><br/>
    // <p>Thanks and Regards,<br/>takeUforward Team</p>
    // `;

    // const { handleSubmit: handleEmailSubmit, control: emailControl, formState: { errors: emailErrors, isSubmitted: isEmailSubmitted } } = useForm({
    //     defaultValues: {
    //         sendEmailTitle: `TUF+ | Your BugId[${id}]`, 
    //         sendEmailMessage: emailMessage, 
    //     }
    // });

    const handleStatusChange = (e) => {
        e.stopPropagation();
        setBugStatus(e.target.value);
        dispatch(updateBugStatus(id, e.target.value, email));
        // if(e.target.value === 'resolved'){
        //     dispatch(setEmailContent({})); 
        //     setEmailModalOpen(true);
        // } 
    };

    const handleCategoryChange = (e) => {
        setBugCategory(e.target.value);
        dispatch(updateBugSection(id, e.target.value));
    };

    const handleAssignedToChange = (e) => {
        const changedFromUnassigned = assignedTo === '';
        setAssignedTo(e.target.value);
        if(changedFromUnassigned) {
            dispatch(updateBugStatus(id, 'inProgress'));
        }
        dispatch(assignBugToUser(id, e.target.value, changedFromUnassigned, email));
    };

    const handleView = (e) => {
        e.stopPropagation();
        openModal(id);
      };
      

    const formatDate = useCallback((date) => {
        const d = new Date(date);
        return isNaN(d.getTime()) ? 'Invalid date' : d.toISOString().split('T')[0];
    // eslint-disable-next-line
    }, []);

    // const handleSendEmail = async (data) => {
    //     const { sendEmailTitle, sendEmailMessage } = data;
    //     setIsSending(true);
    //     const toastId = toast.loading('Sending email. Please wait...');
    //     try {
    //         dispatch(sendEmailToUser(sendEmailTitle, sendEmailMessage, email));     
    //         toast.success('Email sent successfully!', { id: toastId });
    //     } catch (error) {
    //         toast.error('An error occurred while sending email.', { id: toastId });
    //     } 
    //     finally{
    //         setIsSending(false);
    //         setEmailModalOpen(false);
    //     }    
    // }

    const handleDelete = (e) => {
        e.stopPropagation(); 
        dispatch(deleteBug(id, setDeleteModal)); 
    };

    return (
        <tbody className="text-[13px] overflow-y-auto no-scrollbar">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {num}.
                    </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props.id}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {title.length > 40 ? `${title.slice(0, 40)}...` : title}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <select
                            className="w-2/3 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out"
                            value={bugCategory}
                            onChange={handleCategoryChange}
                        >
                            <option value="editorial">Editorial</option>
                            <option value="video">Video</option>
                            <option value="problem">Problem</option>
                            <option value="tech">Tech</option>
                            <option value="others">Others</option>
                        </select>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {formatDate(time)}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <select
                            className="bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out"
                            value={bugStatus}
                            onChange={handleStatusChange}
                        >
                            <option value="open">Open</option>
                            <option value="resolved">Resolved</option>
                            <option value="inProgress">In Progress</option>
                            <option value="awaiting reply">Awaiting Reply</option>
                        </select>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <select
                            className="bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out"
                            value={assignedTo}
                            onChange={handleAssignedToChange}
                            >
                            <option value="">Unassigned</option>
                            {teamMembers.length > 0 && teamMembers.map((member) => (
                                <option key={member.id} value={member.id}>
                                {member.username}
                                </option>
                            ))}
                        </select>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className='flex flex-row gap-x-6 items-center justify-center'>
                    <div className='cursor-pointer'>
                        <button onClick={handleView}
                            className="text-brand underline" type='button'>View</button>
                    </div>
                    <svg className='cursor-pointer' onClick={(e) => {e.stopPropagation(); setDeleteModal(true) }} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.30775 17.5017C2.81058 17.5017 2.385 17.3247 2.031 16.9707C1.677 16.6167 1.5 16.1911 1.5 15.6939V3.00169H1.25C1.0375 3.00169 0.859417 2.92977 0.71575 2.78594C0.571917 2.6421 0.5 2.46394 0.5 2.25144C0.5 2.03877 0.571917 1.86069 0.71575 1.71719C0.859417 1.57352 1.0375 1.50169 1.25 1.50169H5C5 1.25685 5.08625 1.04819 5.25875 0.875688C5.43108 0.703355 5.63967 0.617188 5.8845 0.617188H10.1155C10.3603 0.617188 10.5689 0.703355 10.7413 0.875688C10.9138 1.04819 11 1.25685 11 1.50169H14.75C14.9625 1.50169 15.1406 1.5736 15.2843 1.71744C15.4281 1.86127 15.5 2.03944 15.5 2.25194C15.5 2.4646 15.4281 2.64269 15.2843 2.78619C15.1406 2.92985 14.9625 3.00169 14.75 3.00169H14.5V15.6939C14.5 16.1911 14.323 16.6167 13.969 16.9707C13.615 17.3247 13.1894 17.5017 12.6923 17.5017H3.30775ZM13 3.00169H3V15.6939C3 15.7838 3.02883 15.8575 3.0865 15.9152C3.14417 15.9729 3.21792 16.0017 3.30775 16.0017H12.6923C12.7821 16.0017 12.8558 15.9729 12.9135 15.9152C12.9712 15.8575 13 15.7838 13 15.6939V3.00169ZM6.15425 14.0017C6.36675 14.0017 6.54483 13.9299 6.6885 13.7862C6.832 13.6424 6.90375 13.4642 6.90375 13.2517V5.75169C6.90375 5.53919 6.83183 5.36102 6.688 5.21719C6.54433 5.07352 6.36617 5.00169 6.1535 5.00169C5.941 5.00169 5.76292 5.07352 5.61925 5.21719C5.47575 5.36102 5.404 5.53919 5.404 5.75169V13.2517C5.404 13.4642 5.47583 13.6424 5.6195 13.7862C5.76333 13.9299 5.94158 14.0017 6.15425 14.0017ZM9.8465 14.0017C10.059 14.0017 10.2371 13.9299 10.3807 13.7862C10.5243 13.6424 10.596 13.4642 10.596 13.2517V5.75169C10.596 5.53919 10.5242 5.36102 10.3805 5.21719C10.2367 5.07352 10.0584 5.00169 9.84575 5.00169C9.63325 5.00169 9.45517 5.07352 9.3115 5.21719C9.168 5.36102 9.09625 5.53919 9.09625 5.75169V13.2517C9.09625 13.4642 9.16817 13.6424 9.312 13.7862C9.45567 13.9299 9.63383 14.0017 9.8465 14.0017Z" fill="#5F6368" />
                    </svg>

                </div>
                </td>
            </tr>
            <DeleteModal id="delete-modal" modalOpen={deleteModal} setModalOpen={setDeleteModal}>
                <div className="px-5 pt-4 pb-1">
                    <div className="flex justify-center flex-col items-center">
                        <div className="p-2 rounded-full bg-red-500/20 w-fit">
                            <AlertTriangle className="stroke-red-600" size={32} />
                        </div>
                        <p className="font-bold text-2xl mt-1">Are you sure?</p>
                        <div className="flex flex-col justify-center items-center mt-6">
                            <span>This action cannot be undone.</span>
                            <span>Do you want to delete this bug?</span>
                        </div>
                        <div className="mt-4">
                            <button onClick={handleDelete} className="w-full text-md rounded-lg relative inline-flex items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-2 border-l-2 border-r-2  active:border-red-700 active:shadow-none shadow-lg bg-gradient-to-tr from-red-600 to-red-500 hover:from-red-500 hover:to-red-500  border-red-700 text-white">Delete</button>
                        </div>
                    </div>
                </div>
            </DeleteModal>
            {/* <EmailModal title={'Send Email To User'} setModalOpen={setEmailModalOpen} modalOpen={emailModalOpen}>
                <form onSubmit={handleEmailSubmit(handleSendEmail)} className='mt-6'>
                    <div className="flex flex-wrap -mx-3 mb-4">
                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Title</span>
                        <div className="w-full px-3">
                            <Controller
                                name="sendEmailTitle"
                                control={emailControl}
                                render={({ field }) => (
                                    <input
                                        id="sendEmailTitle"
                                        type="text"
                                        name="sendEmailTitle"
                                        className="form-input w-full text-gray-300"
                                        placeholder="Title"
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: 'Title is required',
                                }}
                            />
                            {isEmailSubmitted && emailErrors.sendEmailTitle && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailTitle.message}</p>}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Message</span>
                        <div className="w-full px-3">
                            <Controller
                                name="sendEmailMessage"
                                control={emailControl}
                                render={({ field }) => (
                                    <ReactQuill
                                        id="sendEmailMessage"
                                        theme="snow"
                                        name="sendEmailMessage"
                                        className="w-full text-gray-800 dark:text-zinc-200"
                                        placeholder="Email message to be sent."
                                        rows={5}
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: 'Message is required',
                                }}
                            />
                            {isEmailSubmitted && emailErrors.sendEmailMessage && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailMessage.message}</p>}
                        </div>
                    </div>
                               
                    <div className="flex flex-wrap -mx-3 mt-6">
                        <div className="w-full px-3">
                            <button type="submit" className="btn-brand-2 w-full">Send Email</button>
                        </div>
                    </div>
                </form>
            </EmailModal>  */}
        </tbody>
    )
}

export default BugsTableItem