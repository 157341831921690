/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReportedBugsTable from '../../../components/Table/BugReportsTable/BugsTable'
import { fetchAssignedBugs } from '../../../services/operations/bugs'
import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from '../../../components/Header/Header'
import DateRangePicker from '../../../components/DateRangePicker'
import { format } from "date-fns"
import TablePagination from '../../../components/Table/TablePagination'

const AssignedBugs = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [priority, setPriority] = useState('');
    
    // const [startDate, setStartDate] = useState(() => {
    //     const now = new Date();
    //     return new Date(now.getFullYear(), now.getMonth(), 1); // Start of current month
    // });
    const [startDate, setStartDate] = useState(new Date(2025, 0, 1)); // January 1, 2025
    const [endDate, setEndDate] = useState(new Date()); // Today

    const [status, setStatus] = useState("open");
    const itemsPerPage = 25;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const dispatch = useDispatch();
    const { assignedBugs } = useSelector((state) => state.bugs)
    
    const formatDate = (date) => {
        return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
    };

    useEffect(() => {
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        dispatch(fetchAssignedBugs(priority, status, formattedStartDate, formattedEndDate, currentPage));
    }, [dispatch, priority, startDate, endDate, status, currentPage]);

    const handlePriorityChange = (event) => {
        setPriority(event.target.value);
    };
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderCategoryBugs = () => {
        return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto no-scrollbar overflow-x-hidden">
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto mb-8">
                    {/* Status Filter */}
                    <div className="mb-6 flex gap-x-4 border-b">
                        {["open", "resolved", "awaiting reply", "inProgress"].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setStatus(tab)}
                                className={`px-4 py-2 ${
                                    status === tab
                                        ? "border-b-2 border-brand text-brand"
                                        : "text-gray-600"
                                }`}
                            >
                                {tab.toUpperCase()}
                            </button>
                        ))}
                    </div>
                    <div className="mb-4 flex items-center gap-x-4 justify-between">
                        {/* Priority filter */}
                        <div className="flex items-center gap-x-4">
                            <label htmlFor="priority" className="text-md font-medium text-gray-800 dark:text-zinc-200">Priority:</label>
                            <select
                                id="priority"
                                value={priority || ''}
                                onChange={handlePriorityChange}
                                className="form-select"
                            >
                                <option value="">All</option>
                                <option value="high">High</option>
                                <option value="mid">Medium</option>
                                <option value="low">Low</option>
                            </select>
                        </div>

                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>

                {assignedBugs.data && assignedBugs.data.length > 0 ? (
                    <>
                        <ReportedBugsTable bugs={assignedBugs.data} />
                        <div className="  border dark:border-dark_40 bg-[#F4F6F8] dark:bg-[#141414] border-gray-200  rounded-b-2xl mt-3">
                            <TablePagination
                                itemsPerPage={itemsPerPage}
                                totalItems={assignedBugs.totalRecords}
                                currentPage={currentPage}
                                paginate={paginate}
                                indexOfFirstItem={indexOfFirstItem}
                                indexOfLastItem={indexOfLastItem}
                            />
                        </div>
                    </>
                ) : (
                    <div className='flex justify-center items-center text-3xl pt-4 dark:text-zinc-300'>
                       No Bugs !!!
                    </div>
                )}
            </div>
        </div>
        </div>
        );
    };

    return (
        <main className="dark:bg-dark font-primary h-screen overflow-y-auto no-scrollbar bg-[#fafafa]">
            {renderCategoryBugs()}
        </main>
    );
};

export default AssignedBugs;