import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDetailedBugCounts,
    fetchEnhancedStats,
} from "../../services/operations/bugs"; 
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Clock, Flag, Bug, CheckCircle, AlertTriangle, ReplyAllIcon, TimerIcon } from "lucide-react"; 
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";

function HomePage() {
    const dispatch = useDispatch();
    const { sections, stats, loading, error } = useSelector((state) => state.bugs);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    // const [startDate, setStartDate] = useState(() => {
    //     const now = new Date();
    //     return new Date(now.getFullYear(), now.getMonth(), 1); // Start of current month
    // });
    const [startDate, setStartDate] = useState(new Date(2025, 0, 1)); // January 1, 2025
    const [endDate, setEndDate] = useState(new Date()); // Today

    const formatDate = (date) => {
        return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    useEffect(() => {
        if (formattedStartDate && formattedEndDate) {
            dispatch(fetchDetailedBugCounts(formattedStartDate, formattedEndDate));
            dispatch(fetchEnhancedStats(formattedStartDate, formattedEndDate));
        }
    }, [dispatch, formattedStartDate, formattedEndDate]);
    

    return (
        <div className="flex font-primary h-screen overflow-hidden bg-white dark:bg-dark_50 ">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto no-scrollbar overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="p-8  min-h-screen">

            <div className="flex justify-between items-center">
                <h1 className="text-3xl font-bold text-center mb-8 text-zinc-800 dark:text-zinc-100">Bug Statistics</h1>

                {/* Date Range Picker */}
                <div className="flex justify-center mb-8 space-x-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Start Date</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="mt-1 p-2 rounded-md w-48 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 "
                            placeholderText="Select start date"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">End Date</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className="mt-1 p-2 rounded-md w-48 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 "
                            placeholderText="Select end date"
                        />
                    </div>
                </div>
            </div>

            {/* Loading and Error States */}
            {loading && <p className="text-center text-gray-500">Loading...</p>}
            {error && <p className="text-center text-red-500">{error}</p>}
            
              {/* Overall Statistics */}
              <div className="mb-8">
                {stats ? (
                    <div className="p-8 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300">
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-8">
                            {/* Average Resolution Time */}
                            <div className="flex items-center gap-4">
                                <Clock className="text-purple-600 text-3xl" />
                                <div>
                                    <p className="text-sm text-gray-700 dark:text-gray-300">Avg. Resolution Time</p>
                                    <p className="text-xl font-bold text-purple-800 dark:text-purple-400">
                                    {isNaN(parseFloat(stats.avg_resolution_time)) || parseFloat(stats.avg_resolution_time) === 0
                                        ? "N/A"
                                        : `${parseFloat(stats.avg_resolution_time).toFixed(2)} hrs`}
                                    </p>
                                </div>
                            </div>
                            
                            {/* Total Bugs */}
                            <div className="flex items-center gap-4">
                            <Flag className="text-blue-600 text-3xl" />
                            <div>
                                <p className="text-sm text-gray-700 dark:text-gray-300">Total Bugs</p>
                                <p className="text-xl font-bold text-blue-800 dark:text-blue-400">{stats.total_bugs}</p>
                            </div>
                            </div>

                            {/* Resolved Bugs */}
                            <div className="flex items-center gap-4">
                            <Flag className="text-green-600 text-3xl" />
                            <div>
                                <p className="text-sm text-gray-700 dark:text-gray-300">Resolved Bugs</p>
                                <p className="text-xl font-bold text-green-800 dark:text-green-400">{stats.resolved_bugs}</p>
                            </div>
                            </div>

                            {/* Open Bugs */}
                            <div className="flex items-center gap-4">
                            <Flag className=" text-red-400 text-3xl" />
                            <div>
                                <p className="text-sm text-gray-700 dark:text-gray-300">Open Bugs</p>
                                <p className="text-xl font-bold text-red-800 dark:text-red-400">{stats.open_bugs}</p>
                            </div>
                            </div>

                            {/* Awaiting Reply Bugs */}
                            <div className="flex items-center gap-4">
                            <Flag className="text-yellow-600 text-3xl" />
                            <div>
                                <p className="text-sm text-gray-700 dark:text-gray-300">Awaiting Reply Bugs</p>
                                <p className="text-xl font-bold text-yellow-800 dark:text-yellow-400">{stats.awaiting_reply_bugs}</p>
                            </div>
                            </div>

                            {/* In Progress Bugs */}
                            <div className="flex items-center gap-4">
                            <Flag className="text-orange-600 text-3xl" />
                            <div>
                                <p className="text-sm text-gray-700 dark:text-gray-300">In Progress Bugs</p>
                                <p className="text-xl font-bold text-orange-800 dark:text-orange-400">{stats.progress_bugs}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className="text-gray-500">No statistics available.</p>
                )}
            </div>

            {/* Grid Layout for Categories */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                {Array.isArray(sections) && sections.length > 0 ? (
                    sections.map((section) => (
                        <div
                        key={section.category}
                        className="p-6 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300 flex flex-col gap-4"
                        >
                            {/* Category Title */}
                            <h2 className="text-xl font-semibold text-gray-800 dark:text-zinc-200 capitalize text-center mb-4">
                                {section.category}
                            </h2>

                            {/* Average Resolution Time */}
                            <div className="flex items-center justify-between">
                                <span className="text-gray-600 dark:text-gray-400 text-md">
                                Avg Resolution:
                                </span>
                                <span className="text-lg font-semibold text-yellow-500 dark:text-yellow-200">
                                {Number(section.avg_resolution_time).toFixed(2)} hrs
                                </span>
                            </div>

                            {/* Divider */}
                            <div className="border-t border-gray-200 dark:border-zinc-700 my-4"></div>

                            {/* Bug Stats */}
                            <div className="space-y-2">
                                <div className="flex items-center justify-between">
                                    <div className="flex gap-x-2 text-gray-600 dark:text-gray-400 text-md">
                                        <Bug />
                                        <span>Total:</span>
                                    </div> 
                                    <span className="text-lg font-semibold dark:text-zinc-300">{section.total_bugs}</span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex gap-x-2 text-gray-600 dark:text-gray-400 text-md">
                                        <CheckCircle className="text-green-400"/>
                                        <span>Resolved:</span>
                                    </div> 
                                    <span className="text-lg font-semibold dark:text-zinc-300">{section.resolved_bugs}</span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex gap-x-2 text-gray-600 dark:text-gray-400 text-md">
                                        <AlertTriangle className="text-red-400"/>
                                        <span>Open:</span>
                                    </div> 
                                    <span className="text-lg font-semibold dark:text-zinc-300">{section.open_bugs}</span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex gap-x-2 text-gray-600 dark:text-gray-400 text-md">
                                        <ReplyAllIcon className=" text-yellow-500 dark:text-yellow-200"/>
                                        <span>Awaiting Reply:</span>
                                    </div> 
                                    <span className="text-lg font-semibold dark:text-zinc-300">{section.awaiting_reply}</span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex gap-x-2 text-gray-600 dark:text-gray-400 text-md">
                                        <TimerIcon className="text-purple-800 dark:text-purple-400"/>
                                        <span>In Progress:</span>
                                    </div> 
                                    <span className="text-lg font-semibold dark:text-zinc-300">{section.progress_bugs}</span>
                                </div>
                            </div>

                            {/* Divider */}
                            <div className="border-t border-gray-200 dark:border-zinc-700 my-4"></div>

                            {/* Priority Flags */}
                            <div className="grid grid-cols-2 sm:grid-cols-3">
                                <div className="flex items-center gap-4">
                                    <Flag className="text-red-400 text-3xl" />
                                    <div>
                                        <p className="text-sm text-gray-700 dark:text-gray-300">High:</p>
                                        <p className="text-xl font-bold text-red-800 dark:text-red-400">{section.priority_high}</p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-4">
                                    <Flag className="text-gray-600 text-3xl" />
                                    <div>
                                        <p className="text-sm text-gray-700 dark:text-gray-300">Mid:</p>
                                        <p className="text-xl font-bold text-gray-800 dark:text-gray-400">{section.priority_mid}</p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-4">
                                    <Flag className="text-gray-600 text-3xl" />
                                    <div>
                                        <p className="text-sm text-gray-700 dark:text-gray-300">Low:</p>
                                        <p className="text-xl font-bold text-gray-800 dark:text-gray-400">{section.priority_low}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    
                ) : (
                <p className="text-center text-gray-500 col-span-full">No sections available.</p>
                )}
            </div>
        </div>
        </div>
        </div>
    );
}

export default HomePage;
