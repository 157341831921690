import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bugs: [],                                                       // All bugs
    bugDetails: {},                                                 // Single bug details
    sections: [],                                                   // Bug counts by sections
    teamMembers: [],                                                // Members of the bug team
    member:{},                                                      // for member modal
    emailContent:{},                                                // for email modal
    assignedBugs: { data: [], currentPage: 1, totalRecords: 0 },    // Bugs assigned to a specific user
    stats: null,                                                    // Bug statistics
    loading: false,
    error: null,
    problemBugs: { 
        open: { data: [], currentPage: 1, totalRecords: 0 },
        resolved: { data: [], currentPage: 1, totalRecords: 0 },
        awaiting_reply: { data: [], currentPage: 1, totalRecords: 0 },
        inProgress: { data: [], currentPage: 1, totalRecords: 0 },
    },
    editorialBugs: { 
        open: { data: [], currentPage: 1, totalRecords: 0 },
        resolved: { data: [], currentPage: 1, totalRecords: 0 },
        awaiting_reply: { data: [], currentPage: 1, totalRecords: 0 },
        inProgress: { data: [], currentPage: 1, totalRecords: 0 },
    },
    videoBugs: {
        open: { data: [], currentPage: 1, totalRecords: 0 },
        resolved: { data: [], currentPage: 1, totalRecords: 0 },
        awaiting_reply: { data: [], currentPage: 1, totalRecords: 0 },
        inProgress: { data: [], currentPage: 1, totalRecords: 0 },
    },
    techBugs: {
        open: { data: [], currentPage: 1, totalRecords: 0 },
        resolved: { data: [], currentPage: 1, totalRecords: 0 },
        awaiting_reply: { data: [], currentPage: 1, totalRecords: 0 },
        inProgress: { data: [], currentPage: 1, totalRecords: 0 },
    },
    othersBugs: {
        open: { data: [], currentPage: 1, totalRecords: 0 },
        resolved: { data: [], currentPage: 1, totalRecords: 0 },
        awaiting_reply: { data: [], currentPage: 1, totalRecords: 0 },
        inProgress: { data: [], currentPage: 1, totalRecords: 0 },
    },
};

const bugsSlice = createSlice({
    name: "bugs",
    initialState,
    reducers: {
        setBugDetails(state, action) {
            state.bugDetails = action.payload;
        },
        setSections(state, action) {
            state.sections = action.payload;
        },
        setTeamMembers(state, action) {
            state.teamMembers = action.payload;
        },
        setMember(state, action) {
            state.member = action.payload;
        },
        setEmailContent(state, action) {
            state.emailContent = action.payload;
        },
        setAssignedBugs(state, action) {
            const { data, currentPage, totalRecords } = action.payload;
            state.assignedBugs = {
                data: [...data],
                currentPage,
                totalRecords,
            };
        },        
        setStats(state, action) {
            state.stats = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setBugs(state, action) {
            const { section, status, data, currentPage, totalRecords } = action.payload;
        
            const sanitizedStatus = status.trim().replace(' ', '_'); 
            // Check if the section and status exist in the state before updating
            if (state[`${section}Bugs`] && state[`${section}Bugs`][sanitizedStatus]) {
                state[`${section}Bugs`][sanitizedStatus] = {
                    data: [...data],  // Replace the data to avoid duplicates
                    currentPage,
                    totalRecords,
                };
            }
        },
        updateBugStatusRedux(state, action) {
            if (state.bugDetails?.report_id === action.payload.report_id) {
                state.bugDetails.status = action.payload.status;
            }
        },
        updateBugPriorityRedux(state, action) {
            if (state.bugDetails?.report_id === action.payload.report_id) {
                state.bugDetails.priority = action.payload.priority;
            }
        },
        updateBugSectionRedux(state, action) {
            if (state.bugDetails?.report_id === action.payload.report_id) {
                state.bugDetails.category = action.payload.category;
            }
        },
        assignBugToUserRedux(state, action) {
            if (state.bugDetails?.report_id === action.payload.report_id) {
                state.bugDetails.assigned_to = action.payload.assigned_to;
            }
        },
    },
});

export const {
    setBugs,
    setBugDetails,
    setSections,
    setTeamMembers,
    setMember,
    setEmailContent,
    setAssignedBugs,
    setStats,
    setLoading,
    setError,
    updateBugPriorityRedux,
    updateBugSectionRedux,
    updateBugStatusRedux,
    assignBugToUserRedux
} = bugsSlice.actions;

export default bugsSlice.reducer;