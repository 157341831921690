/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBugById, updateBugStatus, updateBugPriority, updateBugSection, assignBugToUser } from "../../services/operations/bugs";
// import Transition from "../../utils/Transition";
import moment from 'moment';
import { Calendar, CheckCircle, Clock, Flag, LinkIcon } from "lucide-react";
import CommentEditor from "../RichEditor/CommentEditor";
import { markdownToHtml } from "../RichEditor/Parser";
import DOMPurify from "dompurify";
// import { sendEmailToUser } from "../../services/operations/bugs";
// import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
// import EmailModal from "./EmailModal";
// import ReactQuill from 'react-quill';
import { assignBugToUserRedux, setBugDetails, setEmailContent, updateBugPriorityRedux, updateBugSectionRedux, updateBugStatusRedux } from "../../slices/bugSlice";
import { ChevronLeft, ChevronRight } from "lucide-react";
function BugDetailsModal({ bugId, modalOpen, setModalOpen, bugList, setCurrentBugId }) {
  const modalContent = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      setModalOpen(false);
      dispatch(setEmailContent({}));
      dispatch(setBugDetails({}));
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      dispatch(setEmailContent({}));
      dispatch(setBugDetails({}));
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // Selectors for bug details and comments
  const { bugDetails, isLoading, error, teamMembers } = useSelector((state) => state.bugs);

  const [status, setStatus] = useState(bugDetails?.status || '');
  const [priority, setPriority] = useState(bugDetails?.priority || '');
  const [section, setSection] = useState(bugDetails?.category || '');
  const [assignedTo, setAssignedTo] = useState(bugDetails?.assigned_to || '');
  // const [emailModalOpen, setEmailModalOpen] = useState(false);
  // const [isSending, setIsSending] = useState(false);
  const currentIndex = bugList.indexOf(bugId);

  // Fetch bug details on modal open
  useEffect(() => {
    if (modalOpen && bugId) {
      dispatch(fetchBugById(bugId));
    }
  }, [modalOpen, bugId, dispatch]);


  // const {
  //   handleSubmit: handleEmailSubmit,
  //   control: emailControl,
  //   formState: { errors: emailErrors, isSubmitted: isEmailSubmitted },
  //   setValue,
  // } = useForm({
  //   defaultValues: {
  //     sendEmailTitle: '',
  //     sendEmailMessage: '',
  //   }
  // });

  // Dynamically update email title and body when bug details change
  // useEffect(() => {
  //   if (bugDetails && modalOpen) {
  //     const dynamicEmailMessage = `
  //         <p>Hi,</p><br/>
  //         <p>Thank you for reaching out to us.</p>
  //         <p>Our team has resolved the bug titled <strong>${bugDetails.title}</strong> successfully, and we hope it no longer affects the system's functionality.</p>
  //         <p>If you face any problems, do not hesitate to reach out to us.</p><br/>
  //         <p>Thanks and Regards,<br/>takeUforward Team</p>
  //         `;

  //     setValue('sendEmailTitle', `TUF+ | Your BugId[${bugDetails.report_id}]`);
  //     setValue('sendEmailMessage', dynamicEmailMessage);
  //   }
  // }, [bugDetails, modalOpen, setValue]);

  const handleStatusChange = (e) => {
    e.stopPropagation();
    setStatus(e.target.value);
    dispatch(updateBugStatus(bugDetails.report_id, e.target.value));
    dispatch(updateBugStatusRedux({ report_id: bugDetails.report_id, status: e.target.value }));
    // if (e.target.value === 'resolved') {
    //   setEmailModalOpen(true);
    // }
  };

  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
    dispatch(updateBugPriority(bugDetails.report_id, e.target.value));
    dispatch(updateBugPriorityRedux({ report_id: bugDetails.report_id, priority: e.target.value }));
  };

  const handleSectionChange = (e) => {
    setSection(e.target.value);
    dispatch(updateBugSection(bugDetails.report_id, e.target.value));
    dispatch(updateBugSectionRedux({ report_id: bugDetails.report_id, section: e.target.value }));
  };

  const handleAssignedToChange = (e) => {
    const changedFromUnassigned = assignedTo === '';
    setAssignedTo(e.target.value);
    if (changedFromUnassigned) {
      dispatch(updateBugStatus(bugDetails.report_id, 'inProgress'));
    }
    dispatch(assignBugToUser(bugDetails.report_id, e.target.value, changedFromUnassigned, bugDetails.email));
    dispatch(assignBugToUserRedux({ report_id: bugDetails.report_id, assigned_to: e.target.value }));
  };

  // Close modal handler
  const handleClose = (e) => {
    e.stopPropagation();
    dispatch(setEmailContent({}));
    dispatch(setBugDetails({}));
    setModalOpen(false);
  };

  const priorityColors = {
    high: "text-red-500 fill-red-500",
    mid: "text-yellow-500 fill-yellow-500",
    low: "text-blue-500 fill-blue-500",
  };

  // const handleSendEmail = async (data) => {
  //   const { sendEmailTitle, sendEmailMessage } = data;
  //   setIsSending(true);
  //   const toastId = toast.loading('Sending email. Please wait...');
  //   try {
  //     dispatch(sendEmailToUser(sendEmailTitle, sendEmailMessage, bugDetails.reported_by));
  //     toast.success('Email sent successfully!', { id: toastId });
  //   } catch (error) {
  //     toast.error('An error occurred while sending email.', { id: toastId });
  //   }
  //   finally {
  //     setIsSending(false);
  //     setEmailModalOpen(false);
  //   }
  // }

  const shareUrl = `${window.location.origin}/get-bug/${bugId}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      toast.success("URL copied to clipboard!");
    }).catch(() => {
      toast.error("Failed to copy the URL.");
    });
  };

  const navigateBug = (direction) => {
    if (direction === "next" && currentIndex < bugList.length - 1) {
      setCurrentBugId(bugList[currentIndex + 1]);
    } else if (direction === "prev" && currentIndex > 0) {
      setCurrentBugId(bugList[currentIndex - 1]);
    }
  };

  return (
    <>
      {/* Overlay */}
      {/* <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      <Transition
        className="fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-6"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      > */}

      <div ref={modalContent} className="bg-white dark:bg-dark_50 rounded-lg shadow-lg overflow-y-auto no-scrollbar w-[80%] max-h-[80%] flex flex-col">
        <div className="flex justify-between items-center px-6 py-4 border-b dark:border-zinc-700">
          <h2 className="font-bold text-xl text-zinc-800 dark:text-zinc-100">
            Bug Details
          </h2>
          <div className="flex items-center space-x-4">
            <button
              disabled={currentIndex === 0}
              onClick={() => navigateBug("prev")}
              className={`flex items-center justify-center px-4 py-2 rounded-lg transition-colors ${currentIndex === 0
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-brand hover:bg-orange-800"
                } text-white`}
              title="Previous Bug"
            >
              <ChevronLeft className="w-5 h-5" /> Prev
            </button>
            <button
              disabled={currentIndex === bugList.length - 1}
              onClick={() => navigateBug("next")}
              className={`flex items-center justify-center px-4 py-2 rounded-lg transition-colors ${currentIndex === bugList.length - 1
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-brand hover:bg-orange-800"
                } text-white`}
              title="Next Bug"
            >
              Next <ChevronRight className="w-5 h-5" />
            </button>
            <button
              className="text-sm font-medium text-red-500 hover:text-red-700"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className="p-6 text-center">Loading...</div>
        ) : error ? (
          <div className="p-6 text-center text-red-500">{error}</div>
        ) : (
          <div className="flex flex-col md:flex-row h-full">
            {/* Left Section: Bug Details */}
            <div className="w-full md:w-1/2 p-8 space-y-6 border-r dark:border-zinc-700 overflow-y-auto no-scrollbar  max-h-[70vh]">
              <div className="flex justify-between">
                <div className="p-2 rounded shadow flex items-center bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 ">
                  <p className="text-md text-gray-400">Bug ID:</p>
                  <p className="ml-2 text-md font-semibold">{bugDetails.report_id}</p>
                </div>
                <div className="p-2 rounded shadow flex items-center bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 ">
                  <p className="text-md text-gray-400">Reported By:</p>
                  <p className="ml-2 text-sm font-semibold">{bugDetails.reported_by}</p>
                </div>
              </div>

              <h1 className="text-2xl font-bold text-zinc-800 dark:text-zinc-100">{bugDetails.title}</h1>

              <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-10">
                {/* Bug Details Time Info*/}
                <div className="space-y-4">
                  <p className="flex items-center gap-x-2">
                    <Flag className={`${priorityColors[bugDetails.priority]}`} />
                    <div className="flex items-center gap-x-2">
                      <label className="text-md font-medium text-gray-500 dark:text-zinc-300">Priority:</label>
                      <select
                        className="w-full rounded p-1 mt-1 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 text-md"
                        value={bugDetails.priority}
                        onChange={handlePriorityChange}
                      >
                        <option value="high">High</option>
                        <option value="mid">Mid</option>
                        <option value="low">Low</option>
                      </select>
                    </div>
                  </p>
                  <p className="flex items-center gap-2">
                    <Calendar className="text-gray-800 dark:text-zinc-400" />
                    <strong className="text-md font-medium text-gray-500 dark:text-zinc-300">Reported On:</strong>{" "}
                    <span className="text-gray-800 dark:text-zinc-300">
                      {moment(bugDetails.reported_time).format("MMM DD, YYYY, h:mm A")}
                    </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <Clock className="text-gray-800 dark:text-zinc-400" />
                    <strong className="text-md font-medium text-gray-500 dark:text-zinc-300">Last Updated:</strong>{" "}
                    <span className="text-gray-800 dark:text-zinc-300">
                      {moment(bugDetails.last_updated_time).format("MMM DD, YYYY, h:mm A")}
                    </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <CheckCircle className="text-green-500" />
                    <strong className="text-md font-medium text-gray-500 dark:text-zinc-300">Closing Time:</strong>{" "}
                    <span className="text-gray-800 dark:text-zinc-300">
                      {bugDetails.closing_time
                        ? moment(bugDetails.closing_time).format("MMM DD, YYYY, h:mm A")
                        : "N/A"}
                    </span>
                  </p>
                </div>

                {/* Dropdown Information */}
                <div className="space-y-4">
                  <div className="flex items-center gap-x-2">
                    <label className="text-md font-medium text-gray-500 dark:text-zinc-300">Section:</label>
                    <select
                      className="w-full rounded p-1 mt-1 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 text-md"
                      value={bugDetails.category}
                      onChange={handleSectionChange}
                    >
                      <option value="editorial">Editorial</option>
                      <option value="video">Video</option>
                      <option value="problem">Problem</option>
                      <option value="tech">Tech</option>
                      <option value="others">Others</option>
                    </select>
                  </div>

                  <div className="flex items-center gap-x-2">
                    <label className="text-md font-medium text-gray-500 dark:text-zinc-300">Status:</label>
                    <select
                      className="w-full rounded p-1 mt-1 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 text-md"
                      value={bugDetails.status}
                      onChange={handleStatusChange}
                    >
                      <option value="open">Open</option>
                      <option value="resolved">Resolved</option>
                      <option value="awaiting reply">Awaiting Reply</option>
                    </select>
                  </div>

                  <div className="flex items-center gap-x-2">
                    <label className="text-md font-medium text-gray-500 dark:text-zinc-300">Assignee:</label>
                    <select
                      className="w-full rounded p-1 mt-1 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 text-md"
                      value={bugDetails?.assigned_to || ""}
                      onChange={handleAssignedToChange}
                    >
                      <option value="">Unassigned</option>
                      {teamMembers.map((member) => (
                        <option key={member.id} value={member.id}>
                          {member.username}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex space-x-4 justify-end">
                    {/* Copy to Clipboard Button */}
                    <button
                      className="px-4 py-2 bg-brand rounded-md text-white hover:bg-orange-800"
                      onClick={copyToClipboard}
                    >
                      Share Bug
                    </button>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 p-4 rounded-md shadow-sm overflow-auto  no-scrollbar max-h-50 dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 ">
                <span className="text-lg font-medium text-gray-400">Description</span>
                <p className="text-sm mt-2">{bugDetails.description}</p>
              </div>
              {bugDetails.screenshot_url && (
                <div className="flex items-center gap-2">
                  <LinkIcon className="text-gray-800 dark:text-zinc-400" />
                  <span className="text-lg font-medium text-gray-500 dark:text-zinc-300">Screenshot URL</span>
                  <a
                    href={bugDetails.screenshot_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block text-brand underline"
                  >
                    View Screenshot
                  </a>
                </div>
              )}
            </div>

            {/* Right Section: Comments */}
            <div className="w-full md:w-1/2 p-6 space-y-4 max-h-[80vh] flex flex-col">
              <h3 className="text-xl font-medium text-gray-800 dark:text-zinc-200">Activity</h3>

              <div className="space-y-4 flex-1 overflow-y-auto no-scrollbar  max-h-[70vh] pb-10">
                {bugDetails.comments?.length > 0 ? (
                  bugDetails.comments.map((comment) => (
                    <div
                      key={comment.comment_id}
                      className={`p-4 shadow rounded-md border border-gray-200 relative ${comment.commentator === "assignee" ?
                          "bg-green-100 dark:bg-blue-100 border border-gray-300 dark:border-zinc-600 ml-8" :
                          "bg-white dark:bg-gray-600 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 mr-8"
                        }`}
                    >
                      <span className="text-xs text-gray-400 absolute top-2 right-2">
                        {moment(comment.created_at).format("MMM DD, YYYY, h:mm A")}
                      </span>
                      {/* Render the comment content as HTML */}
                      <div
                        className="text-sm break-words whitespace-pre-wrap mt-4"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(markdownToHtml(comment.content)),
                        }}
                      ></div>
                    </div>
                  ))
                ) : (
                  <p className="text-sm text-gray-500">No comments available.</p>
                )}
              </div>

              {/* Rich Text Editor */}
              <div className="sticky bottom-2 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200  p-4 shadow-md rounded-md">
                <CommentEditor bugId={bugId} email={bugDetails.reported_by} title={bugDetails?.title} placeholder="Add a comment..." />
              </div>
            </div>
          </div>
        )}

        {/* <EmailModal title={'Send Email To User'} setModalOpen={setEmailModalOpen} modalOpen={emailModalOpen}>
          <form onSubmit={handleEmailSubmit(handleSendEmail)} className='mt-6'>
            <div className="flex flex-wrap -mx-3 mb-4">
              <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Title</span>
              <div className="w-full px-3">
                <Controller
                  name="sendEmailTitle"
                  control={emailControl}
                  render={({ field }) => (
                    <input
                      id="sendEmailTitle"
                      type="text"
                      name="sendEmailTitle"
                      className="form-input w-full text-gray-300"
                      placeholder="Title"
                      {...field}
                    />
                  )}
                  rules={{
                    required: 'Title is required',
                  }}
                />
                {isEmailSubmitted && emailErrors.sendEmailTitle && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailTitle.message}</p>}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Message</span>
              <div className="w-full px-3">
                <Controller
                  name="sendEmailMessage"
                  control={emailControl}
                  render={({ field }) => (
                    <ReactQuill
                      id="sendEmailMessage"
                      theme="snow"
                      name="sendEmailMessage"
                      className="w-full text-gray-800 dark:text-zinc-200"
                      placeholder="Email message to be sent."
                      rows={5}
                      {...field}
                    />
                  )}
                  rules={{
                    required: 'Message is required',
                  }}
                />
                {isEmailSubmitted && emailErrors.sendEmailMessage && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailMessage.message}</p>}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <button type="submit" className="btn-brand-2 w-full">Send Email</button>
              </div>
            </div>
          </form>
        </EmailModal> */}
      </div>
      {/* </Transition> */}
    </>
  );
}

export default BugDetailsModal;
